import { IoCloseOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import React from "react";

// redirectURL for redirecting for other pages during signup
function SuccessModal({
  closeModal,
  redirectUrl,
}: {
  closeModal: () => void;
  redirectUrl?: string;
}) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (redirectUrl) {
      navigate(redirectUrl)
    } else {
      navigate("/");
    }
    closeModal();
  }

  return (
    <div className="fixed inset-0 h-[100vh] m-0 p-0 bg-black bg-opacity-10 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-full max-w-md mx-4">
        <div className="flex justify-end pr-2 pt-4">
          <IoCloseOutline
            className="w-6 h-6 cursor-pointer"
            onClick={closeModal}
          />
        </div>
        <div className="min-h-48 flex justify-center items-center">
          <img src="/success.gif" alt="success gif" />
        </div>
        <div className="space-y-1 w-full p-6">
          <p className="w-full text-lg font-semibold text-center text-black">
            Thankyou !
          </p>
          <p className="text-center text-black">
            Thank you for registering with{" "}
            <span className="font-semibold">Roadlyne</span> our team will
            properly verify your account
          </p>
        </div>
        <div className="p-4 pt-0">
          {redirectUrl ? (
              <Button
                onClick={handleClick}
                className="w-full cursor-pointer bg-black hover:bg-black text-white font-medium py-2 px-4 rounded"
              >
                Continue
              </Button>
          ) : (
            <Button
              onClick={handleClick}
              className="w-full bg-black cursor-pointer hover:bg-black text-white font-medium py-2 px-4 rounded"
            >
              Continue
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
