import { LiaTruckSolid } from "react-icons/lia";
import { RecentLoadCardProps } from "../../../pages/Homepage";
import { clsx } from "clsx";

export default function RecentLoadCard({ ...props }: RecentLoadCardProps) {
  return (
    <div className="relative rounded-lg border h-48 flex flex-col justify-between p-4 gap-2 shadow-sm">
      <div className="flex items-center w-full justify-between">
        <div className="flex items-center gap-2">
          <div className="p-2 bg-neutral-200/30 rounded-md">
            <LiaTruckSolid size={28} className="text-neutral-400" />
          </div>
          <div className="flex flex-col justify-between">
            <p className="text-neutral-400 font-normal text-xs">Order ID</p>
            <p className="text-neutral-700 font-medium text-xs sm:text-sm">
              {props.orderId}
            </p>
          </div>
        </div>
        <div>
          <p className="text-cardinal-700 font-bold text-sm sm:text-base">
            ₹ {props.rate}
          </p>
        </div>
      </div>
      <div className="flex-1">
        <div className="h-full w-full grid grid-cols-3">
          <div className="flex flex-col justify-between h-full">
            <div>
              <p className="text-neutral-400 font-normal text-xs">From</p>
              <p className="font-semibold text-xs sm:text-sm">{props.from}</p>
            </div>
            <div>
              <p className="text-neutral-400 font-normal text-xs">To</p>
              <p className="font-semibold text-xs sm:text-sm">{props.to}</p>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <div className="flex flex-col justify-between h-full">
              <div>
                <p className="text-neutral-400 font-normal text-xs">
                  Vehicle Type
                </p>
                <p className="font-semibold text-xs sm:text-sm">
                  {props.vehicleType}
                </p>
              </div>
              <div>
                <p className="text-neutral-400 font-normal text-xs">Capacity</p>
                <p className="font-semibold text-xs sm:text-sm">
                  {props.vehicleCapacity}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between h-full">
            <div>
              <p className="text-neutral-400 text-right font-normal text-xs">
                Type
              </p>
              <p className="font-semibold text-right text-xs sm:text-sm">
                {props.itemType}
              </p>
            </div>
            <div>
              <p className="text-neutral-400 text-right font-normal text-xs">
                Pickup
              </p>
              <p className="font-semibold text-xs sm:text-sm text-right">
                {props.pickUp.getDate()}{" "}
                {props.pickUp.toLocaleString("default", { month: "short" })}{" "}
                {props.pickUp.getFullYear()}
              </p>
            </div>
          </div>
        </div>
      </div>
      <p
        className={clsx(
          "absolute right-4 -top-[11px] px-3 py-0.5 text-white text-xs sm:text-sm rounded-full",
          {
            "bg-yellow-500": props.status === "Pending",
            "bg-violet-600": props.status === "In Process",
            "bg-green-600": props.status === "Completed",
            "bg-red-600": props.status === "Cancelled",
          },
        )}
      >
        {props.status}
      </p>
    </div>
  );
}
