import React, { useEffect, useRef } from "react";
import { ModalProps } from "../../../lib/definitions";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import InputDatePicker from "../date-picker";

export interface IMarketPlaceOrderFilter {
  status: string[];
  pickUpDate: Date;
  pickUpCity: Date;
  deliveryCity: string;
}

export default function MarketPlaceOrderFilterModal({
  isOpen,
  onClose,
}: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data: IMarketPlaceOrderFilter = {
      status: formData.getAll("status") as string[],
      pickUpDate: new Date(formData.get("pickUpDate") as string),
      pickUpCity: new Date(formData.get("pickUpCity") as string),
      deliveryCity: formData.get("deliveryCity") as string,
    };
    console.log(data);
    onClose();
  };

  if (!isOpen) return null;

  const checkBoxClasses: string =
    "h-4 w-4 border border-cardinal-300 rounded-md cursor-pointer";
  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200 p-5"
        ref={modalRef}
      >
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <h3 className="text-center font-bold">Order Filter</h3>
          <div>
            <Label>Status</Label>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2">
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  name="status"
                  value="bidding"
                  className={checkBoxClasses}
                />
                <Label
                  htmlFor="bidding"
                  className="text-neutral-400 text-sm font-normal"
                >
                  Bidding
                </Label>
              </div>
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  name="status"
                  value="upcoming"
                  className={checkBoxClasses}
                />
                <Label
                  htmlFor="upcoming"
                  className="text-neutral-400 text-sm font-normal"
                >
                  Upcoming
                </Label>
              </div>
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  name="status"
                  value="inProcess"
                  className={checkBoxClasses}
                />
                <Label
                  htmlFor="InProcess"
                  className="text-neutral-400 text-sm font-normal"
                >
                  In Process
                </Label>
              </div>
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  name="status"
                  value="completed"
                  className={checkBoxClasses}
                />
                <Label
                  htmlFor="completed"
                  className="text-neutral-400 text-sm font-normal"
                >
                  Completed
                </Label>
              </div>
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  name="status"
                  value="cancelled"
                  className={checkBoxClasses}
                />
                <Label
                  htmlFor="cancelled"
                  className="text-neutral-400 text-sm font-normal"
                >
                  Cacelled
                </Label>
              </div>
            </div>
          </div>
          <InputDatePicker name="pickUpDate" placeholder="Pick Up Date" />
          <div>
            <Label className="text-neutral-400">Pick up city</Label>
            <Input
              type="text"
              required
              name="pickUpCity"
              className="bg-neutral-50 border border-neutral-200 "
              placeholder="Pick up city"
            />
          </div>
          <div>
            <Label className="text-neutral-400">Delivery city</Label>
            <Input
              type="text"
              required
              name="deliveryCity"
              className="bg-neutral-50 border border-neutral-200"
              placeholder="Delivery city"
            />
          </div>
          <div className="flex justify-center items-center">
            <Button
              type="submit"
              className="w-full max-w-[16rem] bg-cardinal-500 hover:bg-cardinal-600 text-white py-3 rounded-lg"
            >
              Apply
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
