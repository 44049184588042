import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/auth/LoginPage";
import Homepage from "./pages/Homepage";
import LoginVerificationPage from "./pages/auth/LoginVerificationPage";
import SignUpPage from "./pages/auth/SignupPage";
import SignupVerificationPage from "./pages/auth/SignupVerificationPage";
import BusinessProfile1 from "./pages/auth/profile/BussinessProfile1";
import BusinessProfile2 from "./pages/auth/profile/BusinessProfile2";
import BusinessProfile3 from "./pages/auth/profile/BusinessProfile3";
import MainLayout from "./components/app/main-layout";
import ChatPage from "./pages/ChatPage";
import BiltyPage from "./pages/BiltyPage";
import LoadsPage from "./pages/LoadsPage";
import KhataPage from "./pages/KhataPage";
import AboutPage from "./pages/AboutPage";
import HelpPage from "./pages/HelpPage";
import FaqsPage from "./pages/FaqsPage";
import PrivacyPage from "./pages/PrivacyPage";
import MarketPlace from "./pages/MarketPlace";
import ManageDrivers from "./pages/ManageDrivers";
import ManageVehicles from "./pages/ManageVehicles";
import CancellationPage from "./pages/CancellationPage";
import MarketPlaceOrderPage from "./pages/MarketPlaceOrderPage";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Homepage />} />
          <Route path="chat" element={<ChatPage />} />
          <Route path="bilty" element={<BiltyPage />} />
          <Route path="loads" element={<LoadsPage />} />
          <Route path="khata" element={<KhataPage />} />
          <Route path="marketplace" element={<MarketPlace />} />
          <Route path="marketplace/order" element={<MarketPlaceOrderPage />} />
          <Route path="manage-drivers" element={<ManageDrivers />} />
          <Route path="manage-vehicles" element={<ManageVehicles />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="help" element={<HelpPage />} />
          <Route path="faqs" element={<FaqsPage />} />
          <Route path="privacy" element={<PrivacyPage />} />
          <Route path="cancellation-policy" element={<CancellationPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/verification" element={<LoginVerificationPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route
          path="/signup/verification"
          element={<SignupVerificationPage />}
        />
        <Route
          path="/signup/verification/profile1"
          element={<BusinessProfile1 />}
        />
        <Route
          path="/signup/verification/profile2"
          element={<BusinessProfile2 />}
        />
        <Route
          path="/signup/verification/profile3"
          element={<BusinessProfile3 />}
        />
        <Route path="*" element={<div>Not Found</div>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
