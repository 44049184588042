import { useState } from "react";
import { LiaTruckSolid } from "react-icons/lia";
import MarketPlaceChangeDriverVehicleModal from "../modals/marketplace-change-driver-vehicle-modal";
import { PencilIcon } from "lucide-react";
export interface IMarketPlaceVehicle {
  vehicle: string;
  minWeight: number;
  maxWeight: number;
  weightQuantity: string;
}

export default function MarketPlaceVehicleCard({
  ...props
}: IMarketPlaceVehicle) {
  const [showChangeDriverVehicleModal, setShowChangeDriverVehicleModal] =
    useState<boolean>(false);

  const marketPlaceDrivers = ["Aniket", "Pranav", "Bhalaria"];
  const marketPlaceVehicles = ["Truck", "Car"];

  const toggleModal = () => {
    setShowChangeDriverVehicleModal((prev) => !prev);
  };
  return (
    <div className="border relative rounded-md p-2 shadow-sm my-2 text-xs max-w-[6rem]">
      <div
        onClick={toggleModal}
        className="absolute flex items-center justify-center -top-2 -right-1 h-5 w-5 bg-blue-500 rounded-full cursor-pointer p-1 shadow-none hover:bg-blue-500"
      >
        <PencilIcon className="text-white w-4 h-4" />
      </div>
      <div className="p-2  rounded-md w-full flex justify-center items-center">
        <LiaTruckSolid size={28} className="text-neutral-400" />
      </div>
      <div className="text-center w-full space-y-2">
        <p className="font-semibold">{props.vehicle}</p>
        <p className="text-neutral-400">
          {props.minWeight} - {props.maxWeight} {props.weightQuantity}
        </p>
      </div>
      <MarketPlaceChangeDriverVehicleModal
        isOpen={showChangeDriverVehicleModal}
        onClose={toggleModal}
        drivers={marketPlaceDrivers}
        vehicles={marketPlaceVehicles}
      />
    </div>
  );
}
