import React from "react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Separator } from "../../ui/separator";
import LogoImage from "../logo-image";
import OAuthComponent from "../oauth-credentails";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

function SignupForm() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const rawMobile = searchParams.get("mobile") || "";
  const mobile = rawMobile.startsWith("+91") ? rawMobile : "+91" + rawMobile.replace("+91", "");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue.length < 3) {
      setSearchParams({ mobile: "+91" });
      return;
    }

    const numberPart = inputValue.slice(3);
    const regex = /^\d*$/;
    
    if (regex.test(numberPart)) {
      setSearchParams({ mobile: "+91" + numberPart });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (mobile.length < 13) {
      return;
    }
    navigate(`/signup/verification?mobile=${mobile}`);
  };

  return (
    <div className="space-y-3 w-full max-w-md p-5">
      <LogoImage height={160} />
      <form className="flex flex-col space-y-5" onSubmit={handleSubmit}>
        <div className="space-y-1 flex flex-col">
          <Label className="text-neutral-600 text-xs sm:text-sm">
            Enter mobile number to signup
          </Label>
          <div className="relative">
            <Input
              name="mobile"
              className="shadow-none"
              value={mobile}
              onChange={handleChange}
              maxLength={13} // +91 plus 10 digits
              placeholder="+911234567890"
            />
          </div>
        </div>
        <div className="w-full">
          <Button 
            type="submit"
            className="w-full bg-cardinal-700 hover:bg-cardinal-700"
          >
            Send OTP
          </Button>
        </div>
        <div className="w-full flex items-center gap-4">
          <div className="flex-1">
            <Separator />
          </div>
          <span className="text-neutral-500 flex-shrink-0">or</span>
          <div className="flex-1">
            <Separator />
          </div>
        </div>
        <div className="w-full">
          <OAuthComponent />
        </div>
      </form>
    </div>
  );
}

export default SignupForm;