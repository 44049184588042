import DriverDetailsCard, {
  IDriverDetails,
} from "../components/app/cards/driver-details-card";
import MarketPlaceDeliveryLocationCard, {
  IMarketplaceDeliveryLocaton,
} from "../components/app/cards/market-place-delivery-location-card";
import MarketPlaceOrderStatusCard, {
  IMarketPlaceOrderStatus,
  MarketPlaceOrderStatus,
} from "../components/app/cards/marketplace-order-status-card";
import MarketPlacePickupLocationCard, {
  IMarketplacePickupLocaton,
} from "../components/app/cards/marketplace-pickup-location-card";
import MarketPlaceVehicleCard, {
  IMarketPlaceVehicle,
} from "../components/app/cards/marketplace-vehicle-card";
import MarketPlaceItemCard from "../components/app/cards/markteplace-item-card";

const MarketPlaceOrderPage = () => {
  const driverDetails: IDriverDetails = {
    driverName: "Rahul Sharma",
    currentRating: 4.5,
    vehicleType: "Sedan",
    imageUrl: "https://example.com/images/rahul_sharma.jpg",
  };

  const marketPlaceOrderStatus: IMarketPlaceOrderStatus = {
    orderDate: new Date("2024-10-15T14:30:00Z"), // Example date
    cost: 150.75, // Example cost
    consignor: "John Doe", // Example consignor name
    orderStatus: MarketPlaceOrderStatus.Completed, // Example order status
  };

  const marketplacePickupLocation: IMarketplacePickupLocaton = {
    pickupLocation: "Mumbai Central Warehouse",
    pickupDate: new Date("2024-12-22T15:30:00+05:30"),
    pickupAddress:
      "456, A-Wing, 2nd Floor, MG Road, Mumbai, Maharashtra, 400001",
    name: "Aniket Chauhan",
    contact: "+91-12412315123",
  };
  const marketplaceDeliveryLocation: IMarketplaceDeliveryLocaton = {
    deliveryLocation: "Mumbai Central Warehouse",
    deliveryAddress:
      "456, A-Wing, 2nd Floor, MG Road, Mumbai, Maharashtra, 400001",
    name: "Aniket Chauhan",
    contact: "+91-12412315123",
  };

  const marketPlaceVehicle: IMarketPlaceVehicle = {
    minWeight: 2.5,
    maxWeight: 7,
    weightQuantity: "Ton",
    vehicle: "Mini/Pickup",
  };

  const marketItemData = {
    itemName: "Wooden Dining Table",
    itemLength: 12,
    itemWidth: 2,
    itemHeight: 3,
    description:
      "A sturdy wooden dining table with a natural finish, perfect for family gatherings.",
    images: [
      "https://example.com/images/table1.jpg",
      "https://example.com/images/table2.jpg",
      "https://example.com/images/table3.jpg",
    ],
    quantity: 10,
    itemNumber: 1,
  };

  const offeredPrice: number = 1000;

  const numberOfItems: number = 2;

  return (
    <div className="w-full px-5 grid gap-5 md:grid-cols-2 mb-10">
      <div className="space-y-4 w-full">
        <MarketPlaceOrderStatusCard {...marketPlaceOrderStatus} />
        <div className="p-3 flex gap-3 justify-between items-center shadow rounded-md text-xs sm:text-sm">
          <div className="font-medium">
            <p className="text-neutral-400">Your Offered</p>
            <p>Price</p>
          </div>
          <p className="font-semibold text-blue-500 text-sm">
            ₹ {offeredPrice}
          </p>
        </div>
        <div>
          <h3 className="font-semibold text-sm sm:text-base">Driver Details</h3>
          <DriverDetailsCard {...driverDetails} />
        </div>
        <MarketPlacePickupLocationCard {...marketplacePickupLocation} />
        <MarketPlaceDeliveryLocationCard {...marketplaceDeliveryLocation} />
        <div>
          <h3 className="font-semibold text-sm sm:text-base">Vehicle Type</h3>
          <MarketPlaceVehicleCard {...marketPlaceVehicle} />
        </div>
      </div>
      <div className="space-y-4 w-full">
        <div className="flex justify-between items-center gap-3">
          <h3 className="font-semibold text-sm sm:text-base">Items</h3>
          <div className="flex justify-center items-center flex-col text-xs sm:text-sm">
            <p className="text-neutral-400">Number of Items</p>
            <p className="flex justify-center items-center w-6 h-6 text-white rounded-full bg-cardinal-600">
              {numberOfItems}
            </p>
          </div>
        </div>
        <div className="space-y-2">
          <h3 className="font-semibold text-sm sm:text-base">Instruction</h3>
          <MarketPlaceItemCard {...marketItemData} />
          <div className="rounded-md shadow p-3">
            <p className="text-neutral-400 text-xs sm:text-sm">
              We allow the users to share the information for expansion of
              service to the customers by providing the better rates and
              vehicles
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketPlaceOrderPage;