export interface IMarketPlaceItem {
  itemName: string;
  itemLength: number;
  itemWidth: number;
  itemHeight: number;
  description: string;
  images: string[];
  quantity: number;
  itemNumber: number;
}

export default function MarketPlaceItemCard({ ...props }: IMarketPlaceItem) {
  return (
    <div className="p-3 shadow rounded-md space-y-4 py-4 text-xs sm:text-sm">
      <div className="space-y-2">
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">
            {props.itemNumber}. {props.itemName}
          </p>
          <p className="text-cardinal-600">{props.quantity}Qty</p>
        </div>
        <div className="flex items-center justify-between gap-2">
          <p className="text-neutral-400">Dimensions (In feet)</p>
          <div className="flex gap-2">
            <p className="px-2 rounded-md border text-cardinal-600 border-cardinal-200 bg-cardinal-100">
              {props.itemLength}L
            </p>
            <p className="px-2 rounded-md border text-cardinal-600 border-cardinal-200 bg-cardinal-100">
              {props.itemWidth}W
            </p>
            <p className="px-2 rounded-md border text-cardinal-600 border-cardinal-200 bg-cardinal-100">
              {props.itemHeight}H
            </p>
          </div>
        </div>
      </div>
      <div className="space-y-2">
        <h4 className="font-semibold">Description</h4>
        <p className="text-neutral-400 w-full max-w-xs">{props.description}</p>
      </div>
      <div className="space-y-2">
        <h4 className="font-semibold">Images</h4>
        <div className="grid grid-cols-2 gap-5 md:grid-cols-3">
          {props.images.map((imageUrl) => (
            <img src={imageUrl} alt="item" key={imageUrl} />
          ))}
        </div>
      </div>
      <div className="border border-neutral-100" />
      <div className="space-y-2">
        <div className="flex items-center justify-between gap-2">
          <p className="font-semibold">
            {props.itemNumber}. {props.itemName}
          </p>
          <p className="text-cardinal-600 font-medium">{props.quantity}Qty</p>
        </div>
        <div className="flex items-center justify-between gap-2">
          <p className="text-neutral-400">Dimensions (In feet)</p>
          <div className="flex gap-2">
            <p className="px-2 rounded-md border text-cardinal-600 border-cardinal-200 bg-cardinal-100">
              {props.itemLength}L
            </p>
            <p className="px-2 rounded-md border text-cardinal-600 border-cardinal-200 bg-cardinal-100">
              {props.itemWidth}W
            </p>
            <p className="px-2 rounded-md border text-cardinal-600 border-cardinal-200 bg-cardinal-100">
              {props.itemHeight}H
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
