import { useSearchParams } from "react-router-dom";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Search } from "lucide-react";
import MarketPlaceOrderCard from "../components/app/cards/marketplace-order-card";
import { LoadStatus, RecentLoadCardProps } from "./Homepage";

const MarketPlace = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const obj = {
      orderId: formData.get("orderId") as string,
    };
    console.log(obj);
  };

  const recentLoadData: RecentLoadCardProps[] = [
    {
      orderId: "ORD001",
      status: LoadStatus.InProcess,
      from: "Mumbai",
      to: "Delhi",
      rate: 2000,
      vehicleType: "Container Truck",
      vehicleCapacity: "20 tons",
      itemType: "Electronics",
      pickUp: new Date("2024-10-01"),
    },
    {
      orderId: "ORD002",
      status: LoadStatus.Completed,
      from: "Bengaluru",
      to: "Chennai",
      rate: 1500,
      vehicleType: "Flatbed Truck",
      vehicleCapacity: "15 tons",
      itemType: "Textiles",
      pickUp: new Date("2024-10-01"),
    },
  ];

  return (
    <div className="w-full px-5 mb-10">
      <div className="flex justify-center items-center my-10 w-full">
        <form
          onClick={handleSubmit}
          className="w-full max-w-xl flex justify-center items-center"
        >
          <div className="relative w-full">
            <Label htmlFor="search">
              <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-neutral-400 h-4 w-4" />
            </Label>
            <Input
              placeholder="Search"
              id="search"
              name="orderId"
              value={searchParams.get("orderId") || ""}
              onChange={(e) => setSearchParams({ orderId: e.target.value })}
              className="pl-9 pr-4 h-10 shadow-none border border-neutral-200 bg-neutral-50 rounded-md w-full"
            />
          </div>
        </form>
      </div>
      <div className="grid sm:grid-cols-2 gap-5 justify-center w-full">
        {recentLoadData.map((load) => (
          <MarketPlaceOrderCard key={load.orderId} {...load} />
        ))}
      </div>
    </div>
  );
}


export default  MarketPlace