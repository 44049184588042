import { formatDate } from "../../../lib/utils";

export enum MarketPlaceOrderStatus {
  Bidding = "Bidding",
  Completed = "Completed",
  Current = "Current",
}
// add more enum here accordingly

export interface IMarketPlaceOrderStatus {
  orderDate: Date;
  cost: number;
  consignor: string;
  orderStatus: MarketPlaceOrderStatus;
}

export default function MarketPlaceOrderStatusCard({
  ...props
}: IMarketPlaceOrderStatus) {
  return (
    <div className="p-3 w-full space-y-2 text-xs rounded-md shadow">
      <div className="flex items-center justify-between gap-3">
        <div className="flex gap-2 items-center">
          <p className="text-white bg-yellow-500 rounded-md px-2 py-0.5">
            {props.orderStatus}
          </p>
          <p className="font-semibold">
            <span className="font-semibold text-neutral-400">Order Date</span>{" "}
            {formatDate(new Date(props.orderDate))}
          </p>
        </div>
        <p>Cost</p>
      </div>
      <div className="flex justify-between gap-3 items-center">
        <p>{props.consignor}</p>
        <p className="text-sm font-bold text-cardinal-600">₹{props.cost}</p>
      </div>
    </div>
  );
}
