import React, { useEffect, useRef } from "react";
import { ModalProps } from "../../../lib/definitions";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../../ui/select";
import { Label } from "../../ui/label";
import { Button } from "../../ui/button";

export interface IMarketPlaceChangeDriver extends ModalProps {
  vehicles: string[];
  drivers: string[];
}

export default function MarketPlaceChangeDriverVehicleModal({
  isOpen,
  onClose,
  ...rest
}: IMarketPlaceChangeDriver) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      // Check if the click target is part of a select dropdown
      const target = e.target as HTMLElement;
      if (target.closest('[role="listbox"]')) {
        return;
      }

      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = {
      assignVehicle: formData.get("assignVehicle") as string,
      assignDriver: formData.get("assignDriver") as string,
    };
    console.log(data);
    onClose();
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200 p-5"
        ref={modalRef}
      >
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <h3 className="text-center font-bold text-sm">
            Change Driver & Vehicle
          </h3>
          <div className="space-y-1">
            <Label className="text-neutral-400">Assign Vehicle</Label>
            <Select name="assignVehicle" required>
              <SelectTrigger className="bg-neutral-50 border-neutral-200">
                <SelectValue
                  placeholder="Select Vehicle"
                  className="bg-neutral-50 border-neutral-200"
                />
              </SelectTrigger>
              <SelectContent className="bg-neutral-50 border-neutral-200">
                {rest.vehicles.map((vehicle) => (
                  <SelectItem
                    key={vehicle}
                    value={vehicle}
                    className="bg-neutral-50"
                  >
                    {vehicle}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="space-y-1">
            <Label className="text-neutral-400">Assign Driver</Label>
            <Select name="assignDriver" required>
              <SelectTrigger className="bg-neutral-50 border-neutral-200">
                <SelectValue
                  placeholder="Select Driver"
                  className="bg-neutral-50 border-neutral-200"
                />
              </SelectTrigger>
              <SelectContent className="bg-neutral-50 border-neutral-200">
                {rest.drivers.map((driver) => (
                  <SelectItem
                    key={driver}
                    value={driver}
                    className="bg-neutral-50"
                  >
                    {driver}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <Button
            type="submit"
            className="w-full bg-cardinal-500 hover:bg-cardinal-600 text-white py-3 rounded-lg"
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
}
