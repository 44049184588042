import { MapPin, PhoneIcon } from "lucide-react";
import { formatDate } from "../../../lib/utils";
import { Button } from "../../ui/button";

export interface IMarketplacePickupLocaton {
  pickupLocation: string;
  pickupDate: Date;
  pickupAddress: string;
  name: string;
  contact: string;
}

export default function MarketPlacePickupLocationCard({
  ...props
}: IMarketplacePickupLocaton) {
  return (
    <div className="space-y-1 text-xs  rounded-md ">
      <div className="space-y-2 text-xs  rounded-md shadow p-3">
        <h3 className="font-semibold text-sm sm:text-base mb-4 px-2">
          Pick Up Location
        </h3>
        <div className="flex sm:items-center flex-col sm:flex-row gap-3 justify-between">
          <div className="flex items-center gap-3">
            <MapPin size={24} className="text-green-600" />
            <div className="space-y-1">
              <p className="font-medium">Pick up at {props.pickupLocation}</p>
              <p className="text-neutral-400 max-w-[12rem]">
                {props.pickupAddress}
              </p>
            </div>
          </div>
          <div className="space-y-2 text-right">
            <p>Pick Up Date</p>
            <p>{formatDate(props.pickupDate)}</p>
          </div>
        </div>
      </div>
      <div className="p-3 w-full space-y-2 rounded-md shadow text-xs">
        <div className="flex items-center gap-3 justify-between">
          <p className="font-semibold text-sm sm:text-base px-2">
            Contact person info for Pick Up
          </p>
          <Button
            size="icon"
            className="bg-blue-100 hover:bg-blue-100 rounded-full"
          >
            <PhoneIcon size={16} className="text-blue-600" />
          </Button>
        </div>
        <div className="flex items-center gap-3 justify-between sm:text-sm">
          <div className="space-y-1">
            <p className="text-neutral-400">Name</p>
            <p className="font-medium">{props.name}</p>
          </div>
          <div className="space-y-1">
            <p className="text-neutral-400 text-right">Contact No.</p>
            <p className="text-right">{props.contact}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
