import BusinessProfileParent from "../../../components/app/business-profile-parent";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import React, { useState } from "react";
import { Button } from "../../../components/ui/button";
import SuccessModal from "../../../components/app/success-modal";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

const BusinessProfile3 = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    shopNo: "D-420",
    area: "",
    state: "",
    city: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log("Form submitted:", formData);
    setShowModal((prev) => !prev);
    // todo: handle dashboard navigation here
  };

  const toggleModal = () => setShowModal((prev) => !prev);

  return (
    <BusinessProfileParent>
      <div className="w-full mb-10 sm:h-[70vh] flex justify-center items-center p-5 pt-0 sm:pt-5">
        <div className="bg-cardinal-300/30  bg-opacity-45 p-6 rounded-lg max-w-sm md:max-w-md w-full">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg font-semibold">Business Profile</h2>
            <p className="text-cardinal-500">
              <span className="text-xl sm:text-2xl">3</span>
              <span className="text-neutral-900">/3</span>
              <div className="text-xs text-neutral-900">Completed</div>
            </p>
          </div>

          <div className="space-y-2">
            <h3 className="text-cardinal-500 font-medium mb-4">Address Info</h3>

            <form onSubmit={handleSubmit} className="space-y-1">
              <div>
                <Label className="text-xs text-neutral-400">
                  Shop No./ Factory
                </Label>
                <Input
                  type="text"
                  name="shopNo"
                  value={formData.shopNo}
                  onChange={handleInputChange}
                  className="w-full rounded-md border bg-whiye shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                />
              </div>

              <div>
                <Label className="text-xs text-neutral-400">Area</Label>
                <Input
                  type="text"
                  name="area"
                  placeholder="Area"
                  value={formData.area}
                  onChange={handleInputChange}
                  className="w-full rounded-md border bg-whiye shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                />
              </div>
              <div>
                <Label className="text-xs text-neutral-400">State</Label>
                <div className="relative">
                  <Select>
                    <SelectTrigger className="w-full bg-white">
                      <SelectValue placeholder="State" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="kolkata">Kolkata</SelectItem>
                      <SelectItem value="shimla">Shimla</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div>
                <Label className="text-xs text-neutral-400">City</Label>
                <div className="relative">
                  <Select>
                    <SelectTrigger className="w-full bg-white">
                      <SelectValue placeholder="City" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="kolkata">Kolkate</SelectItem>
                      <SelectItem value="shimla">Shimla</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <div className="pt-4 flex justify-end">
                <Button
                  type="submit"
                  className="w-full max-w-[13rem] bg-cardinal-600 text-white py-3 rounded-md hover:bg-cardinal-600 transition-colors"
                >
                  Go To Dashboard
                </Button>
              </div>
            </form>
          </div>
        </div>
        {showModal && <SuccessModal closeModal={toggleModal} />}
      </div>
    </BusinessProfileParent>
  );
}

export default BusinessProfile3;
