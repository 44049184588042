import SearchLoadForm from "../components/app/forms/SearchLoadForm";
import BiltyHomeCard from "../components/app/cards/bilty-home-card";
import RecentLoadCard from "../components/app/cards/recent-load-card";

export enum LoadStatus {
  Pending = "Pending",
  InProcess = "In Process",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export interface RecentLoadCardProps {
  orderId: string;
  status: LoadStatus; // change it to enum acc to backend
  from: string;
  to: string;
  rate: number;
  vehicleType: string;
  vehicleCapacity: string;
  itemType: string;
  pickUp: Date;
}

enum BiltyStatus {
  Pending = "Pending",
  Delivered = "Delivered",
  Cancelled = "Cancelled",
}

export interface BiltyCardProps {
  lrNumber: string;
  orders: number;
  date: Date;
  vehicleNumber: string;
  from: string;
  status: BiltyStatus;
  to: string;
  fromLocation: string;
  toLocation: string;
}

const  Homepage = () => {
  const biltyData: BiltyCardProps[] = [
    {
      lrNumber: "LR123456",
      orders: 3,
      date: new Date("2024-10-01"),
      vehicleNumber: "ABC-1234",
      from: "Warehouse A",
      status: BiltyStatus.Pending,
      to: "Retail Store B",
      fromLocation: "City X",
      toLocation: "City Y",
    },
    {
      lrNumber: "LR789012",
      orders: 5,
      date: new Date("2024-10-15"),
      vehicleNumber: "XYZ-5678",
      from: "Warehouse C",
      status: BiltyStatus.Delivered,
      to: "Customer D",
      fromLocation: "City Z",
      toLocation: "City W",
    },
  ];

  const recentLoadData: RecentLoadCardProps[] = [
    {
      orderId: "ORD001",
      status: LoadStatus.InProcess,
      from: "Mumbai",
      to: "Delhi",
      rate: 2000,
      vehicleType: "Container Truck",
      vehicleCapacity: "20 tons",
      itemType: "Electronics",
      pickUp: new Date("2024-10-01"),
    },
    {
      orderId: "ORD002",
      status: LoadStatus.Completed,
      from: "Bengaluru",
      to: "Chennai",
      rate: 1500,
      vehicleType: "Flatbed Truck",
      vehicleCapacity: "15 tons",
      itemType: "Textiles",
      pickUp: new Date("2024-10-01"),
    },
  ];

  return (
    <div className="w-full space-y-3 mb-10">
      <div className="">
        <h3 className="font-bold text-lg px-5 sm:text-2xl">Search Loads</h3>
        <div className="grid lg:grid-cols-2 gap-5">
          <div className="space-y-4 px-5 h-full w-full">
            <SearchLoadForm />
          </div>
          <div className="w-full h-full px-5 flex justify-center items-center">
            <img
              src="/home/home-delivery.svg"
              alt="home delivery"
              className=" h-full"
            />
          </div>
        </div>
      </div>

      {recentLoadData.length > 0 && biltyData.length > 0 ? (
        <div className="space-y-3">
          <div className="space-y-4 px-5">
            <h3 className="font-bold text-lg sm:text-2xl">Recent Loads</h3>
            <div className="grid gap-5 lg:grid-cols-2 xl:grid-cols-3 ">
              {recentLoadData.map((load) => (
                <RecentLoadCard {...load} key={load.orderId} />
              ))}
            </div>
          </div>
          <div className="space-y-4 px-5">
            <h3 className="font-bold text-lg sm:text-2xl">Bilty</h3>
            <div className="grid gap-5 md:grid-cols-2">
              {biltyData.map((bilty) => (
                <BiltyHomeCard {...bilty} key={bilty.lrNumber} />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3 flex justify-center items-center">
          <img
            src="/home/creditcard.svg"
            alt="credit"
            className="h-48 sm:h-56 my-10"
          />
        </div>
      )}
    </div>
  );
}

export default Homepage;