import { StarIcon, PhoneIcon } from "lucide-react";
import { Button } from "../../ui/button";

export interface IDriverDetails {
  driverName: string;
  currentRating: number;
  vehicleType: string;
  imageUrl: string;
}

export default function DriverDetailsCard({ ...props }: IDriverDetails) {
  return (
    <div className="p-3 shadow rounded-md flex items-center justify-between gap-3 text-xs">
      {/* replace image url source here */}
      <div className="flex gap-3 items-center">
        <img src="/depth.png" alt="driver" />
        <div className="text-left space-y-1">
          <p className="text-neutral-400">Driver</p>
          <p className="font-bold">{props.driverName}</p>
          {/* add clsx for changing star color accordingly */}
          <div className="flex items-center">
            <StarIcon className="text-yellow-500 mr-1" size={12} />
            <p className="font-medium">({props.currentRating})</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 justify-between items-center shadow-none">
        <Button
          size="icon"
          className="bg-blue-100 hover:bg-blue-100 rounded-full"
        >
          <PhoneIcon size={16} className="text-blue-600" />
        </Button>
        <div className="bg-cardinal-600 rounded-2xl text-white px-1.5 py-0.5 font-normal">
          Truck
        </div>
      </div>
    </div>
  );
}
