import { BiltyCardProps } from "../../../pages/Homepage";

export default function BiltyHomeCard({ ...props }: BiltyCardProps) {
  return (
    <div className="flex flex-col text-xs sm:text-xs space-y-3 shadow  rounded-md">
      <div className="w-full flex justify-between items-center px-4 pt-2">
        <div className="flex gap-1">
          <p>LR No:</p>
          <p className="font-bold">{props.lrNumber}</p>
        </div>
        <div className="flex gap-1 items-center">
          <p className="font-bold">Orders</p>
          <p className="bg-cardinal-600 px-2 py-0.5 text-white rounded-md">
            {props.orders}
          </p>
        </div>
      </div>
      <div className="w-full flex justify-between items-center px-4">
        <div className="flex gap-1">
          <p className="font-bold">Bilty Date:</p>
          <p className="text-cardinal-600 font-bold">
            {props.date.getDate()}{" "}
            {props.date.toLocaleString("default", { month: "short" })}{" "}
            {props.date.getFullYear()}
          </p>
        </div>
        <div className="flex gap-1">
          <p>Vehicle No:</p>
          <p className="text-cardinal-600 font-bold">{props.vehicleNumber}</p>
        </div>
      </div>
      <div className="flex flex-col px-4">
        <img src="/home/bilty-card.svg" alt="bilty" />
        <div className="flex items-center justify-between">
          <p className="font-medium">{props.from}</p>
          {/* todo:  change the bgcolor acc to status depend  */}
          <p className="bg-green-600 rounded-md py-0.5 text-white px-2">
            {props.status}
          </p>
          <p className="font-medium">{props.to}</p>
        </div>
      </div>
      <div className="bg-neutral-200/30 px-3 py-2 rounded-b-md flex justify-between items-center">
        <p>
          {props.fromLocation}{" "}
          <span className="text-cardinal-500 font-medium">+3</span>
        </p>
        <p>
          {props.toLocation}{" "}
          <span className="text-cardinal-500 font-medium">+3</span>
        </p>
      </div>
    </div>
  );
}
