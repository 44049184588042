import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";

function SearchLoadForm() {
  return (
    <form className="px-4 py-2 h-full flex flex-col space-y-2 shadow rounded-sm">
      <div>
        <Label className="text-neutral-400">Pick Up City</Label>
        <Input className="shadow-none" placeholder="Pick Up City" />
      </div>
      <div>
        <Label className="text-neutral-400">Delivery City</Label>
        <Input className="shadow-none" placeholder="Delivery City" />
      </div>
      <div className="flex items-center justify-center pt-3">
        <Button className="bg-cardinal-600 w-full max-w-xs hover:bg-cardinal-600 text-white">
          SUBMIT
        </Button>
      </div>
    </form>
  );
}

export default SearchLoadForm;
